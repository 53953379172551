import React from 'react'
import { Box, Container, Grid, GridItem, Stack } from '@chakra-ui/react'
import ContactUsText from './components/ContactUsText'
import ContactUsForm from './components/ContactUsForm'

const ContactUs = () => {
  return (
    <Box bg="#E1DEE3" >
      <Container maxW={"7xl"} py={6} id="contacts">
        <Stack direction={{ xl: "row", lg: "row", md: "row", sm: "row", base: "column" }} gap={{ base: 30, sm: 30, md: 15, lg: 35, xl: 40 }} justify="space-between">
          <Box w={{ xl: "50%", lg: "50%", md: "50%", sm: "50%", base: "100%" }}>
            <ContactUsText />
          </Box>
          <Box w={{ xl: "50%", lg: "50%", md: "50%", sm: "50%", base: "100%" }}>
            <ContactUsForm />
          </Box>
        </Stack>
      </Container>
    </Box>

  )
}

export default ContactUs