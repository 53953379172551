import { useState, useEffect } from 'react';
import { Box, Button, Text, Link, useDisclosure } from '@chakra-ui/react';
import TermsModal from '../TermsAndConditions/TermsModal';

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);
  const {
    isOpen: isTermsModalOpen,
    onOpen: onTermsModalOpen,
    onClose: onTermsModalClose,
  } = useDisclosure();

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowConsent(false);
  };

  return (
    showConsent && (
      <Box
        position="fixed"
        bottom="0"
        width="100%"
        bg="rgba(29, 27, 22, 0.8)"
        p={4}
        zIndex="1000"
        textAlign="center"
        backdropFilter="blur(5px)"
      >
        <Text mb={3} color="white">
          We use cookies to improve your experience on our site. Read our{' '}
          <Link color="#B9314F" fontWeight={700} onClick={() => onTermsModalOpen()}>
            privacy policy
          </Link>
          .
        </Text>
        <Button
          onClick={handleAccept}
          border="1px solid #B9314F"
          bg='transparent'
          color="white"
          size={{ base: 'sm', md: 'sm', lg: 'md' }}
          _hover={{
            bg: '#B9314F',
            color: "white",
            fontWeight: 600,
          }}
        >
          Close
        </Button>
        {isTermsModalOpen && (
          <TermsModal isOpen={isTermsModalOpen} onClose={onTermsModalClose} />
        )}
      </Box>
    )
  );
};

export default CookieConsent;
