import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MotionBox = motion(Box);

const TitleOfServices = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
  }, [controls, inView]);

  return (
    <MotionBox
      p="10px"
      border="0.5px solid rgba(185, 49, 79, 0.4)"
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={controls}
    >
      <Stack direction="column" spacing={5} textAlign="left">
        <Text fontWeight={700} fontSize={{ xl: 20, lg: 20, md: 18, sm: 16, base: 16 }} color="#B9314F">
          Our Services
        </Text>
        <Flex direction="row">
          <Text fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }} color="#1A1423">
            Business jets should make work and life easier. That’s why{' '}
            <Box as="span" fontWeight={700} fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }} color="#B9314F">
              Amestair
            </Box>{' '}
            offers customized solutions for aircraft management and airworthiness maintenance, freeing you from concerns about technical servicing and compliance with all regulatory requirements.
          </Text>
        </Flex>
      </Stack>
    </MotionBox>
  );
};

export default TitleOfServices;
