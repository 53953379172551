import React from 'react';
import {
  ChakraProvider,
  Grid,
  theme
} from '@chakra-ui/react';
import WelcomeSection from './views/LandingPage/WelcomeBlock/WelcomeSection';
import NavMenu from './views/LandingPage/NavBar/NavMenu';
import AboutSection from './views/LandingPage/AboutUs/AboutSection';
import ServicesSection from './views/LandingPage/OurServices/ServicesSection';
import ContactUsMain from './views/LandingPage/ContactUs/ContactUs';
import Footer from './views/LandingPage/Footer/Footer';
import CookieConsent from './views/LandingPage/CookieConsent/CookieConsent';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <NavMenu />
      <Grid gap={{ xl: 40, lg: 40, md: 20, sm: 20, base: 10 }} bg="#E1DEE3">
        <WelcomeSection />
        <AboutSection />
        <ServicesSection />
        <ContactUsMain />
        <Footer />
      </Grid>
      <CookieConsent />
    </ChakraProvider>
  );
}

export default App;
