import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import planeImage from "../../../../images/backgroundMap/airplaneRedForMap.png";

const RandomPlanes = () => {
  const [planes, setPlanes] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newPlane = {
        id: Math.random(),
        x: Math.random() * 100,
        y: Math.random() * 100,
        isFadingOut: false,
      };
      setPlanes((prev) => [...prev, newPlane]);

      setTimeout(() => {
        setPlanes((prev) =>
          prev.map((plane) =>
            plane.id === newPlane.id ? { ...plane, isFadingOut: true } : plane
          )
        );

        setTimeout(() => {
          setPlanes((prev) => prev.filter((plane) => plane.id !== newPlane.id));
        }, 1000);
      }, 4000);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {planes.map((plane) => (
        <motion.img
          key={plane.id}
          src={planeImage}
          alt="plane"
          style={{
            position: "absolute",
            top: `${plane.y}%`,
            left: `${plane.x}%`,
            width: "50px",
            height: "50px",
            zIndex: 2,
          }}
          initial={{ opacity: 0 }}
          animate={{
            x: 300,
            y: -300,
            opacity: plane.isFadingOut ? 0 : 1,
          }}
          transition={{ duration: plane.isFadingOut ? 1 : 5 }}
        />
      ))}
    </>
  );
};

export default RandomPlanes;