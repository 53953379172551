import { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { motion } from 'framer-motion'
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Container,
  Image,
  Divider,
} from '@chakra-ui/react';

// ICONS
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

// LOGO
import headerLogo from '../../../images/logo/airplaneLogo.png';

const NAV_ITEMS = [
  {
    label: 'About',
    href: '#about',
  },
  {
    label: 'Services',
    href: '#services',
  },
  {
    label: 'Contacts',
    href: '#contacts',
  }
]


const colorBackground = "rgba(29, 27, 22, 0.8)"

export default function NavMenu() {
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowNav(true);
      } else {
        setShowNav(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <Container
        maxW={'7xl'}
        position="absolute"
        zIndex="10"
        left="50%"
        transform="translateX(-50%)"
        bg="transparent"
      >
        <NavContent />
      </Container>


      <Box
        bg={colorBackground}
        w="100%">
        <Container
          maxW={"xxl"}
          position="fixed"
          zIndex="11"
          left="50%"
          transform="translateX(-50%)"
          transition="top 0.5s ease-in-out, opacity 0.5s ease-in-out"
          top={showNav ? '0' : '-100px'}
          opacity={showNav ? 1 : 0}
          bg={colorBackground}
          borderBottom="0.5px solid"
          borderColor="#B9314F"
          backdropFilter="blur(5px)"
        >
          <ScrolledNavContent />
        </Container>
      </Box>
    </>
  );
}

const NavContent = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const variants = {
    open: { opacity: 1, x: 0, transition: { duration: 1 } },
    closed: { opacity: 0, x: "-100%", transition: { duration: 1 } },
  };


  return (
    <Box>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
        <Flex
          minH={'60px'}
          py={{ base: 2 }}
          borderBottom={1}
          align={'center'}
          justifyContent={{ base: 'center', md: 'space-between' }}
        >
          <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
            <IconButton
              color="whiteAlpha.800"
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} color="#B9314F" /> : <HamburgerIcon w={5} h={5} color="#B9314F" />}
              variant={'none'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex justify={{ base: 'center', md: 'start' }}>
            <ScrollLink
              to="home"
              smooth={true}
              duration={500}
              spy={true}
              offset={-70}
            >
              <Link>
                <Image src={headerLogo} alt='header-logo-ae' maxW={{ base: "55px", md: "55px", lg: "65px" }} maxH={{ base: "55px", md: "55px", lg: "65px" }} />
              </Link>
            </ScrollLink>
          </Flex>
          <Flex display={{ base: 'none', md: 'flex' }} ml={10} alignItems='center'>
            <DesktopNav />
          </Flex>

          <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={3}>
            <ScrollLink to="contacts" smooth={true} duration={800}>
              <Button
                fontSize={'sm'}
                fontWeight={600}
                border="1px solid #B9314F"
                color="white"
                bg='#B9314F'
                size={{ base: 'sm', md: 'sm', lg: 'md' }}
                _hover={{
                  bg: 'transparent',
                  color: "#1A1423",
                  fontWeight: 600,
                }}
              >
                Contact Us
              </Button>
            </ScrollLink>
          </Stack>
        </Flex>

        <motion.div
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          style={{ position: "absolute", top: "70px", left: 0, right: 0, background: "transparent" }}
        >
          <MobileNav />
        </motion.div>
      </motion.div>
    </Box>
  );
};

const ScrolledNavContent = () => {
  const { isOpen, onToggle } = useDisclosure();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const variants = {
    open: { opacity: 1, x: 0, transition: { duration: 1 } },
    closed: { opacity: 0, x: "-100%", transition: { duration: 1 } },
  };

  return (
    <Box>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: isVisible ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
        <Flex
          minH={'60px'}
          py={{ base: 2 }}
          // px={{ base: 4 }}
          borderBottom={1}
          align={'center'}
          justifyContent={{ base: 'center', md: 'space-between' }}
        >
          <Flex flex={{ base: 1, md: 'auto' }} ml={{ base: -2 }} display={{ base: 'flex', md: 'none' }}>
            <IconButton
              color="whiteAlpha.800"
              onClick={onToggle}
              icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
              variant={'none'}
              aria-label={'Toggle Navigation'}
            />
          </Flex>
          <Flex justify={{ base: 'center', md: 'start' }}>
            <ScrollLink to="home" smooth={true} duration={800}>
              <Link>
                <Image src={headerLogo} alt='header-logo-ae' maxW={{ base: "55px", md: "55px", lg: "65px" }} maxH={{ base: "55px", md: "55px", lg: "65px" }} />
              </Link>
            </ScrollLink>
          </Flex>
          <Flex display={{ base: 'none', md: 'flex' }} ml={10} alignItems='center'>
            <ScrolledDesktopNav />
          </Flex>

          <Stack flex={{ base: 1, md: 0 }} justify={'flex-end'} direction={'row'} spacing={3}>
            <ScrollLink to="contacts" smooth={true} duration={800}>
              <Button
                fontSize={'sm'}
                fontWeight={600}
                border="1px solid #B9314F"
                color="white"
                bg='#B9314F'
                size={{ base: 'sm', md: 'sm', lg: 'md' }}
                _hover={{
                  bg: 'transparent',
                  color: "white",
                  fontWeight: 600,
                }}
              >
                Contact Us
              </Button>
            </ScrollLink>
          </Stack>
        </Flex>

        <motion.div
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          style={{ position: "absolute", top: "70px", left: 0, right: 0, background: "transparent" }}
        >
          <MobileNav />
        </motion.div>
      </motion.div>
    </Box>
  );
};

const DesktopNav = () => {
  const linkColor = useColorModeValue('white', 'white');
  const linkHoverColor = useColorModeValue("#B9314F", '#B9314F');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Flex direction="row" alignItems="center">
                <ScrollLink
                  to={navItem.href?.replace('#', '')}
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-70}
                >
                  <Link
                    p={2}
                    fontSize={{ base: "sm", md: "md", lg: "md" }}
                    fontWeight={700}
                    color="#1A1423"
                    _hover={{
                      color: linkHoverColor,
                      transform: 'scale(1.5)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  >
                    {navItem.label}
                  </Link>
                </ScrollLink>
              </Flex>

            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))
      }
    </Stack >
  );
};

const ScrolledDesktopNav = () => {
  const linkColor = useColorModeValue('white', 'white');
  const linkHoverColor = useColorModeValue("#B9314F", '#B9314F');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Flex>
                <ScrollLink
                  to={navItem.href?.replace('#', '')}
                  smooth={true}
                  duration={500}
                  spy={true}
                  offset={-70}
                >
                  <Link
                    p={2}
                    fontSize={{ base: "sm", md: "md", lg: "md" }}
                    fontWeight={600}
                    color={linkColor}
                    _hover={{
                      textDecoration: 'none',
                      color: linkHoverColor,
                      transform: 'scale(1.5)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  >
                    {navItem.label}
                  </Link>
                </ScrollLink>
              </Flex>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg='#B9314F'
      p={4}
      borderRadius="4px"
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={1} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontSize={{ base: "sm", md: "md", lg: "md" }}
          fontWeight={600}
          color={useColorModeValue('white', 'white')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>
    </Stack>
  );
};
