import { Box, Flex, IconButton, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { motion } from 'framer-motion';
import { MdEmail } from "react-icons/md";
import { MdPhoneInTalk } from "react-icons/md";

const fadeInVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.3, // Задержка для каждого элемента
      duration: 0.6, // Длительность анимации
    },
  }),
};

const ContactUsTitle = () => {
  return (
    <Box>
      <Flex direction="column" textAlign="start" gap={5}>
        <motion.div custom={0} initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInVariants}>
          <Text fontWeight={700} fontSize={{ xl: 20, lg: 20, md: 18, sm: 16, base: 16 }} color="#B9314F">
            Contact Us
          </Text>
        </motion.div>

        <motion.div custom={1} initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInVariants}>
          <Text fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }} color="#1A1423">
            We are always here to help! If you have any questions about our services, want to discuss partnership details, or need clarification on anything, feel free to reach out to us. Our specialists are ready to assist you and provide the best solutions for your needs. Simply fill out the form below or use our contact details, and we’ll get back to you as soon as possible.
          </Text>
        </motion.div>

        <motion.div custom={2} initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInVariants}>
          <Flex gap={3}>
            <Link href="mailto:contact@amestair.com" isExternal>
              <IconButton
                icon={<MdEmail />}
                borderRadius={0}
                boxSize={{ xl: 50, lg: 50, md: 38, sm: 38, base: 35 }}
                fontSize={{ xl: "30px", lg: "30px", md: "20px", sm: "20px" }}
                variant='outline'
                borderColor={"#B9314F"}
                color="#B9314F"
                _hover={{ bgColor: "#B9314F", color: "White" }}
              />
            </Link>
            <Link href="tel:+971585879262" isExternal>
              <IconButton
                icon={<MdPhoneInTalk />}
                borderRadius={0}
                boxSize={{ xl: 50, lg: 50, md: 38, sm: 38, base: 35 }}
                fontSize={{ xl: "30px", lg: "30px", md: "20px", sm: "20px" }}
                variant='outline'
                borderColor={"#B9314F"}
                color="#B9314F"
                _hover={{ bgColor: "#B9314F", color: "White" }}
              />
            </Link>
          </Flex>
        </motion.div>
      </Flex>
    </Box>
  );
};

export default ContactUsTitle;
