import { Box, Flex, Grid, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { motion } from 'framer-motion';

import { RiDingdingLine } from "react-icons/ri";
import { GrVmMaintenance } from "react-icons/gr";
import { Ri24HoursFill } from "react-icons/ri";
import { GiAutoRepair } from "react-icons/gi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoIosAirplane } from "react-icons/io";

const serviceData = [
  {
    title: "Comprehensive Fleet Management",
    text: "Full-service management for business jets, including flight coordination, crew scheduling, and operational support",
    image: RiDingdingLine
  },
  {
    title: "Airworthiness Maintenance and Compliance",
    text: "Ensuring all aircraft meet strict regulatory standards with regular inspections and necessary certifications",
    image: IoIosAirplane
  },
  {
    title: "Documentation and Certification Management",
    text: "Around-the-clock support for all flight operations, technical needs, and emergency situations",
    image: Ri24HoursFill
  },
  {
    title: "Cost Optimization and Financial Management",
    text: "Transparent budgeting and cost-effective solutions for aircraft maintenance, operations",
    image: RiMoneyDollarCircleLine
  },
  {
    title: "Customizable Solutions for Aircraft Owners",
    text: "Tailored services to fit the unique needs of business jet owners, from technical management to operational strategies",
    image: IoIosCheckmarkCircleOutline
  },
  {
    title: "Maintenance and Repair Services",
    text: "Proactive and scheduled maintenance programs, ensuring optimal aircraft performance and safety",
    image: GiAutoRepair
  }
]

const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.2,
      duration: 0.6,
    },
  }),
};

const Services = () => {
  return (
    <Box>
      <Grid
        templateColumns={{ xl: "repeat(3, 1fr)", lg: "repeat(3, 1fr)", md: "repeat(3, 1fr)", sm: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        gap={{ xl: 30, lg: 5, md: 4, sm: 4, base: 4 }}>
        {serviceData.map((service, index) => (
          <Box
            custom={index}
            as={motion.div}
            initial="hidden"
            whileInView="visible"
            whileHover={{ scale: 1.1 }}
            viewport={{ once: true }}
            variants={cardVariants}
            key={index}
            overflow="hidden"
            p="20px"
            bgColor="transparent"
            border="0.5px solid rgba(185, 49, 79, 0.4)"
            transition="transform 0.3s ease-in-out"
            _hover={{ transform: "scale(1.10)" }}
          >
            <Flex
              direction="column"
              gap={{ xl: 5, lg: 5, md: 2, sm: 2, base: 1 }}>
              <Icon as={service.image} boxSize={{ xl: 100, lg: 100, md: 20, sm: 20, base: 12 }} color="#B9314F" />
              <Box textAlign="start">
                <Text fontSize={{ xl: "xl", lg: "xl", md: "lg", sm: "md", base: "md" }} fontWeight="bold" color="#1A1423" mb="5px" minH={{ xl: "65px", lg: "65px", md: "50px", sm: "40px", base: "30px" }}>
                  {service.title}
                </Text>
                <Text fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }} color="#1A1423">
                  {service.text}
                </Text>
              </Box>
            </Flex>
          </Box>
        ))}
      </Grid>
    </Box>
  )
}

export default Services
