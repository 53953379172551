import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";

import worldMap from "../../../../images/backgroundMap/world.svg";
import RandomPlanes from "./RandomPlanes";


const colorBackground = "#1D1B16";

const WelcomeBlock = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      w="100%"
      h="100vh"
      position="relative"
      overflow="hidden"
      bg={colorBackground}
      zIndex={1}
      id="home"
    >
      <Image
        src={worldMap}
        position="absolute"
        top="50%"
        left="50%"
        width="100%"
        height="100%"
        objectFit="cover"
        transform="translate(-50%, -50%)"
        zIndex="-1"
        filter="auto"
        bg="#E1DEE3"
      />
      <RandomPlanes />
      <Container maxW={"7xl"} position="relative">
        <Stack
          minH="100vh"
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "row", md: "row" }}
          justifyContent={{ base: "center", md: "none" }}
        >
          <Stack
            flex={1}
            spacing={{ base: 5, md: 0 }}
            alignItems={{ base: "center", sm: "center", md: "start", lg: "start", xl: "start" }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: isVisible ? 1 : 0 }}
              transition={{ duration: 2 }}
            >
              <Heading
                lineHeight={1.2}
                fontWeight={900}
                fontSize={{ base: "3xl", sm: "4xl", md: "6xl", lg: "7xl" }}
                mb={{ base: 2, sm: 2, md: 5, lg: 5 }}
              >
                <Flex direction="column" textAlign={{ base: "center", sm: "center", md: "start", lg: "start", xl: "start" }}>
                  <Text
                    as={motion.span}
                    position={"relative"}
                    color="#1A1423"
                  >
                    EXCELLENCE
                  </Text>
                  <Text
                    color="#1A1423"
                  >
                    & AIRWORTHINESS.
                  </Text>
                  <Text
                    as={motion.span}
                    position={"relative"}
                    color="#B9314F"
                  >
                    AMESTAIR
                  </Text>
                  <Text
                    mb={{ base: "15px", sm: "5px", md: "10px", lg: "15px" }}
                    fontSize={{ base: "13", sm: "14", md: "16", lg: "16" }}
                    color="#B9314F"
                    fontWeight={500}
                  >
                    Delivering seamless management and ensuring safety in every flight.
                  </Text>
                </Flex>
              </Heading>
            </motion.div>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default WelcomeBlock;
