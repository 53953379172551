import { Box, Flex, Grid, GridItem, Image, useBreakpointValue } from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import PictureOne from '../../../../images/photos/AboutPictureOne.png';
import PictureThree from '../../../../images/photos/AboutPictureThree.jpg';
import PictureFour from '../../../../images/photos/AboutPictureFour.png';
import PictureFive from '../../../../images/photos/AboutPictureFive.jpg';

const MotionGridItem = motion(GridItem);

const ResponsiveImage = () => {
  const imageSrc = useBreakpointValue({
    base: PictureFive,
    sm: PictureFour,
    md: PictureFour,
    lg: PictureFour,
    xl: PictureFour,
  });

  return <Image src={imageSrc} alt="landing airplane" height="100%" width="100%" objectFit="cover" />;
};

const AboutPhotos = () => {
  const { ref: gridRef, inView } = useInView({ triggerOnce: true, threshold: 0.8 });
  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.3, duration: 0.6 }
      }));
    }
  }, [controls, inView]);

  return (
    <Grid
      templateColumns={{ xl: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)', base: 'repeat(1, 1fr)' }}
      templateRows={{ xl: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)', sm: 'repeat(2, 1fr)', base: 'repeat(1, 1fr)' }}
      gap={5}
      ref={gridRef}
    >
      <MotionGridItem
        rowSpan={{ xl: 2, lg: 2, md: 2, sm: 2, base: 1 }}
        colSpan={1}
        custom={0}
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
      >
        <ResponsiveImage />
      </MotionGridItem>

      <MotionGridItem
        colSpan={1}
        custom={1}
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
      >
        <Image src={PictureOne} alt="landing airplane" height="100%" width="100%" objectFit="cover" />
      </MotionGridItem>

      <MotionGridItem
        colSpan={1}
        custom={2}
        initial={{ opacity: 0, y: 50 }}
        animate={controls}
      >
        <Image src={PictureThree} alt="landing airplane" height="100%" width="100%" objectFit="cover" />
      </MotionGridItem>
    </Grid>
  );
};

export default AboutPhotos;