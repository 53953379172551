import React from 'react'
import ServiceCards from './components/ServiceCards'
import ServiceText from './components/ServiceText'
import { Box, Container, Flex, Stack } from '@chakra-ui/react'

const ServicesSection = () => {
  return (
    <Box h="auto" bg="#E1DEE3" id="services">
      <Flex>
        <Container maxW={"7xl"} py={6}>
          <Stack direction={"column"} spacing={{ xl: 30, lg: 30, md: 15, sm: 15, base: 15 }} justify="center" align="center">
            <Box>
              <ServiceText />
            </Box>
            <Box>
              <ServiceCards />
            </Box>
          </Stack>
        </Container>
      </Flex>
    </Box>
  )
}

export default ServicesSection