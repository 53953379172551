import { Box, Container, Flex, Link, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import TermsModal from '../TermsAndConditions/TermsModal';

const Footer = () => {

  const {
    isOpen: isTermsModalOpen,
    onOpen: onTermsModalOpen,
    onClose: onTermsModalClose,
  } = useDisclosure();

  return (
    <Box bgColor="#B9314F" w="100%" minH="60px">
      <Container maxW={"7xl"} py={{ base: 3, sm: 4, md: 4, lg: 5, xl: 5 }}>
        <Flex
          direction={{ xl: "row", lg: "row", md: "row", sm: "row", base: "column" }}
          justifyContent="space-between"
          color="white"
          gap={{ base: 1, sm: 30, md: 15, lg: 35, xl: 40 }}
        >
          <Text fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}>
            © 2024 Amestair. All rights reserved.
          </Text>
          <Link
            onClick={() => onTermsModalOpen()}
            _hover={{
              color: "whiteAlpha.800",
            }}
            fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
          >
            Privacy Policy
          </Link>
        </Flex>
      </Container>
      {isTermsModalOpen && (
        <TermsModal isOpen={isTermsModalOpen} onClose={onTermsModalClose} />
      )}
    </Box>
  )
}

export default Footer