import React from 'react'
import AboutUsPhotos from './components/AboutUsPhotos'
import AboutUsText from './components/AboutUsText'
import { Box, Container, Flex, Stack } from '@chakra-ui/react'

const AboutSection = () => {
  return (
    <Box h="auto" bg="#E1DEE3" id="about">
      <Flex>
        <Container maxW={"7xl"}>
          <Stack Stack direction={{ xl: "row", lg: "row", md: "column", sm: "column", base: "column" }} spacing={{ xl: 20, lg: 20, md: 15, sm: 15, base: 10 }} justify="center" align="center" >
            <Box w={{ xl: "40%", lg: "40%", md: "100%", sm: "100%", base: "100%" }}>
              <AboutUsText />
            </Box>
            <Box w={{ xl: "60%", lg: "60%", md: "100%", sm: "100%", base: "100%" }}>
              <AboutUsPhotos />
            </Box>
          </Stack>
        </Container >
      </Flex >
    </Box >
  )
}

export default AboutSection