import { Box, Button, Flex, Link, Stack, Text } from '@chakra-ui/react';
import { Link as ScrollLink } from 'react-scroll';
import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const MotionText = motion(Text);
const MotionButton = motion(Button);

const TitleOfAboutUs = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.4,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.3, duration: 0.8 },
      }));
    }
  }, [controls, inView]);

  return (
    <Box ref={ref}>
      <Stack direction="column" spacing={5} textAlign="left">
        <MotionText
          fontWeight={700}
          fontSize={{ xl: 20, lg: 20, md: 18, sm: 16, base: 16 }}
          color="#B9314F"
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          custom={0}
        >
          About
        </MotionText>

        <MotionText
          fontWeight={600}
          fontSize={{ xl: 35, lg: 35, md: 30, sm: 25, base: 20 }}
          color="#1A1423"
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          custom={1}
        >
          Tailored Aviation Services for Business Jet Owners
        </MotionText>

        <MotionText
          fontSize={{ xl: 'lg', lg: 'lg', md: 'md', sm: 'sm', base: 'sm' }}
          color="#1A1423"
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          custom={2}
        >
          Amestair is a leading company specializing in the management of business jets, ensuring seamless operations for clients worldwide. With a focus on maintaining the airworthiness of aircraft, we provide comprehensive support, from logistical planning to technical maintenance. Our team of experts works tirelessly to uphold the highest standards of safety and efficiency.
        </MotionText>

        <ScrollLink to="contacts" smooth={true} duration={800}>
          <MotionButton
            fontSize={'sm'}
            fontWeight={600}
            border="1px solid #B9314F"
            color="white"
            bg='#B9314F'
            size={{ base: 'sm', md: 'sm', lg: 'md', xl: 'md' }}
            _hover={{
              bg: 'transparent',
              color: '#1A1423',
              fontWeight: 600,
            }}
            initial={{ opacity: 0, y: 50 }}
            animate={controls}
            custom={3}
          >
            Contact Us
          </MotionButton>
        </ScrollLink>
      </Stack>
    </Box>
  );
};

export default TitleOfAboutUs;