import {
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  extendTheme,
  Box,
  Flex,
  Button,
  Toast,
  useBreakpointValue,
  useDisclosure,
  useToast,
  InputGroup,
  Text,
  Checkbox,
  Link
} from "@chakra-ui/react";
import { send } from "emailjs-com";
import { motion, useAnimationControls } from "framer-motion";
import { useEffect, useState } from "react";
import TermsModal from "../../TermsAndConditions/TermsModal";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "#E1DEE3",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  }
});

export default function ContactUsForm() {
  const {
    isOpen: isTermsModalOpen,
    onOpen: onTermsModalOpen,
    onClose: onTermsModalClose,
  } = useDisclosure();
  const toast = useToast();

  // Send mail
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    terms: false,
  });

  const [errors, setErrors] = useState({});

  const handleFormDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });

    // Online validation
    validateField(name, type === "checkbox" ? checked : value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "first_name":
      case "last_name":
      case "message":
        if (value.trim() === "") {
          newErrors[name] = true;
        } else {
          delete newErrors[name];
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          newErrors[name] = true;
        } else {
          delete newErrors[name];
        }
        break;
      case "terms":
        if (!value) {
          newErrors[name] = true;
        } else {
          delete newErrors[name];
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const isFormValid = () => {
    const { first_name, last_name, email, message, terms } = formData;
    const newErrors = {};

    if (first_name.trim() === "") newErrors.first_name = true;
    if (last_name.trim() === "") newErrors.last_name = true;
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) newErrors.email = true;
    if (message.trim() === "") newErrors.message = true;
    if (!terms) newErrors.terms = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      Toast({
        title: "Error",
        description: "Please fill in all fields and accept the terms.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      getWithExpiry();
      if (localStorage.getItem("ContactUs") === null) {
        await send(
          "service_50418ms",
          "template_nv3tbzb",
          formData,
          "xq9UdZwuBbu8GKRuK"
        );
        toast({
          title: "Success",
          description: "Message sent successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          message: "",
          terms: false,
        });
        setErrors({});
        setWithExpiry();
      } else {
        toast({
          title: "Email not sent",
          description:
            "You already sent email today. Please try to contact us by E-mail.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  function setWithExpiry() {
    const now = new Date();

    const item = {
      value: "32111",
      expiry: now.getTime() + 86400000,
    };
    localStorage.setItem("ContactUs", JSON.stringify(item));
  }

  function getWithExpiry() {
    const itemStr = localStorage.getItem("ContactUs");
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem("ContactUs");
      return null;
    }
    return item.value;
  }

  const fadeInVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };


  return (
    <ChakraProvider theme={theme}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeInVariants}
      >
        <Flex direction="column" border="0.5px solid rgba(185, 49, 79, 0.4)" p="20px" gap={5}>
          <FormControl
            variant="floating"
            id="first_name"
            isInvalid={errors.first_name}
          >
            <InputGroup
              borderColor={
                errors.first_name
                  ? "red.500"
                  : "rgba(185, 49, 79, 0.4)"
              }
            >
              <Input
                errorBorderColor='rgba(185, 49, 79, 0.4)'
                focusBorderColor="#B9314F"
                placeholder=" "
                type="text"
                color="#1A1423"
                name="first_name"
                value={formData.first_name}
                onChange={(e) => handleFormDataChange(e)}
                fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
              />
              <FormLabel bgColor="transparent" color="black" fontWeight={400} fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}>First name</FormLabel>
            </InputGroup>
          </FormControl>


          <FormControl
            variant="floating"
            id="last_name"
            isInvalid={errors.last_name}
          >
            <InputGroup
              borderColor={
                errors.last_name
                  ? "red.500"
                  : "rgba(185, 49, 79, 0.4)"
              }
            >
              <Input
                errorBorderColor='rgba(185, 49, 79, 0.4)'
                focusBorderColor="#B9314F"
                placeholder=" "
                type="text"
                color="#1A1423"
                name="last_name"
                value={formData.last_name}
                onChange={(e) => handleFormDataChange(e)}
                fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
              />
              <FormLabel bgColor="transparent" color="black" fontWeight={400} fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}>Last Name</FormLabel>
            </InputGroup>
          </FormControl>


          <FormControl
            variant="floating"
            id="email"
            isInvalid={errors.email}
          >
            <InputGroup
              borderColor={
                errors.first_name
                  ? "red.500"
                  : "rgba(185, 49, 79, 0.4)"
              }
            >
              <Input
                errorBorderColor='rgba(185, 49, 79, 0.4)'
                focusBorderColor="#B9314F"
                placeholder=" "
                type="text"
                color="#1A1423"
                name="email"
                value={formData.email}
                onChange={(e) => handleFormDataChange(e)}
                fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
              />
              <FormLabel bgColor="transparent" color="black" fontWeight={400} fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}>E-mail</FormLabel>
            </InputGroup>
          </FormControl>

          <FormControl
            variant="floating"
            id="message"
            isInvalid={errors.message}
          >
            <InputGroup
              borderColor={
                errors.first_name
                  ? "red.500"
                  : "rgba(185, 49, 79, 0.4)"
              }
            >
              <Input
                errorBorderColor='rgba(185, 49, 79, 0.4)'
                focusBorderColor="#B9314F"
                placeholder=" "
                type="text"
                color="#1A1423"
                name="message"
                value={formData.message}
                onChange={(e) => handleFormDataChange(e)}
                fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
              />
              <FormLabel bgColor="transparent" color="black" fontWeight={400} fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}>Message</FormLabel>
            </InputGroup>
          </FormControl>

          <Flex direction="row" w="100%" justifyContent="space-between">
            <FormControl
              id="submit"
              align="left"
              w="30%"
            >
              <Button
                w={{ base: "100%", sm: "100%", md: "auto", lg: "auto" }}
                variant="solid"
                bg="#B9314F"
                color="white"
                onClick={handleSubmit}
                _hover={{ bg: "transparent", color: "#1A1423", border: "1px solid #B9314F", fontWeight: "500" }}
                size={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
                fontSize={{ xl: "lg", lg: "lg", md: "md", sm: "sm", base: "sm" }}
              >
                Send
              </Button>
            </FormControl>
            <Flex gap={2} w="70%" direction="row" alignContent={"center"} alignItems={"center"} justifyContent={"flex-end"}>
              <Text color="#1A1423" fontSize={{ base: '11', sm: '12', md: 'sm', lg: 'sm' }} textAlign={"right"}>
                <Checkbox
                  colorScheme='red'
                  borderColor='rgba(185, 49, 79, 0.4)'
                  name="terms"
                  isChecked={formData.terms}
                  onChange={(e) => handleFormDataChange(e)}
                  mr="7px"
                  mt="4px"
                />
                I agree to the{" "}
                <Link
                  color="#B9314F"
                  onClick={() => onTermsModalOpen()}
                  _hover={{
                    color: "#1A1423",
                  }}
                >
                  terms and conditions
                </Link>
              </Text>
            </Flex>
          </Flex>
          {isTermsModalOpen && (
            <TermsModal isOpen={isTermsModalOpen} onClose={onTermsModalClose} />
          )}
        </Flex>
      </motion.div>
    </ChakraProvider>
  );
}